import AutoApprovalRules from "./components/AutoApprovalRules";
import OrganizationActions from "./components/OrganizationActions";
import OrganizationInfo from "./components/OrganizationInfo";
import ProducerDistributors from "./components/ProducerDistributors";
import ProducerPublishers from "./components/ProducerPublishers";
import ProducerSettings from "./components/ProducerSettings";

export default function SuperAdminOrganizationView({ organization }) {
	return (
		<>
			<OrganizationInfo organization={organization} />
			{organization.type === "producer" && (
				<>
					<ProducerSettings producer={organization} />
					<ProducerPublishers producer={organization} />
					<ProducerDistributors producer={organization} />
					<AutoApprovalRules organization={organization} />
				</>
			)}
			{organization.type === "publisher" && <OrganizationActions organization={organization} />}
		</>
	);
}
