import AdminPageView from "../../../admin/components/AdminPage/AdminPageView";

import SuperAdminDistributionChannelHeader from "./components/SuperAdminDistributionChannelHeader";

export default function SuperAdminDistributionChannelView({ channel }) {
	return (
		<AdminPageView channel={channel}>
			<SuperAdminDistributionChannelHeader channel={channel} />
		</AdminPageView>
	);
}
