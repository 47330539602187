import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../authorization/hooks/useHasRight";

import OrganizationAdminSidebar from "../../components/OrganizationAdminSidebar";

export default function PublisherAdminSidebar() {
	const { t } = useTranslation();

	const hasRight = useHasRight();
	const location = useLocation();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const { pathname } = location;

	const options = [
		{
			path: "articles",
			name: t("articles", "Articles"),
			hidden: !hasRight("articles.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
			active: pathname.includes("admin/articles"),
			options: [
				{
					path: "articles/all",
					active: pathname.endsWith("admin/articles/all"),
					name: t("allArticles", "All articles"),
				},
				{
					path: "articles/drafts",
					name: t("drafts", "Drafts"),
				},
				{
					path: "articles/deliveryNeeded",
					name: t("deliveryNeeded", "Delivery needed"),
				},
				{
					path: "articles/delivered",
					name: t("deliveredPlural", "Delivered"),
				},
				{
					path: "articles/scheduled",
					name: t("scheduledPlural", "Scheduled"),
				},
				{
					path: "articles/notDelivered",
					name: t("notDeliveredPlural", "Not delivered"),
				},
				{
					path: "articles/bundles",
					name: t("bundles", "Bundles"),
				},
				{
					path: "articles/artifacts",
					name: t("artifacts", "Artifacts"),
					active: pathname.includes("admin/articles/artifacts"),
					hidden: !hasRight("artifacts.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
				},
			],
		},
		{
			path: "distributions",
			name: t("distributions", "Distributions"),
			hidden: !hasRight("distributions.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
			active: pathname.includes("admin/distributions"),
			options: [
				{
					path: "distributions/all",
					active: pathname.endsWith("admin/distributions/all"),
					name: t("allDistributions", "All distributions"),
				},
				{
					path: "distributions/completed",
					name: t("completedPlural", "Completed"),
				},
				{
					path: "distributions/scheduled",
					name: t("scheduledPlural", "Scheduled"),
				},
				{
					path: "distributions/aborted",
					name: t("abortedPlural", "Aborted"),
				},
			],
		},
		{
			path: "series",
			name: t("serie", "Series"),
			active: pathname.includes("admin/series"),
			hidden: !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "imprints",
			name: t("imprints", "Imprints"),
			active: pathname.includes("admin/imprints"),
			hidden: !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "scripts",
			name: t("scripts", "Scripts"),
			active: pathname.includes("admin/scripts"),
			hidden: !hasRight("scripts.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "users",
			name: t("users", "Users"),
			active: pathname.includes("admin/users"),
			hidden: !hasRight("users.view"),
		},
		{
			path: "settings/distribution",
			name: t("settings", "Settings"),
			hidden: !hasRight("settings.view"),
			options: [
				{
					path: "settings/distribution",
					name: t("distribution", "Distribution"),
				},
				{
					path: "settings/finance",
					name: t("finance", "Finance"),
				},
				{
					path: "settings/vendors",
					name: t("vendors", "Vendors"),
				},
				{
					path: "settings/language",
					name: t("language", "Language"),
				},
			],
		},
	];

	return <OrganizationAdminSidebar options={options} />;
}
