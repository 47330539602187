import { useParams } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import AdminPageView from "../../admin/components/AdminPage/AdminPageView";
import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminOrganizationView from "../views/SuperAdminOrganizationView/SuperAdminOrganizationView";

export default function SuperAdminPublisher() {
	const { id } = useParams();
	const hasRight = useHasRight();

	const [organization, loading, error] = useDocumentData(id && db.collection("organizations").doc(id));

	if (!hasRight("publishers.admin")) {
		return <Unauthorized />;
	}

	return (
		<LoadingContext loading={loading} error={error} data={organization}>
			<AdminPageView id={id} organization={organization}>
				<SuperAdminOrganizationView />
			</AdminPageView>
		</LoadingContext>
	);
}
