import { useParams } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import AdminPageView from "../../admin/components/AdminPage/AdminPageView";
import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminUserView from "../views/SuperAdminUserView/SuperAdminUserView";

export default function SuperAdminUser() {
	const { id } = useParams();
	const hasRight = useHasRight();

	const [user, loading, error] = useDocumentData(id && db.collection("users").doc(id));

	if (!hasRight("users.admin")) {
		return <Unauthorized />;
	}

	return (
		<LoadingContext loading={loading} error={error} data={user}>
			<AdminPageView id={id} user={user}>
				<SuperAdminUserView />
			</AdminPageView>
		</LoadingContext>
	);
}
