import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";
import { useProfile } from "../../../authentication/state/profile";
import AddUserButton from "../../../users/components/AddUser/AddUserButton";

import useSuperAdminUsersColumns from "./hooks/useSuperAdminUsersColumns";

function getAdminProducerIdsFilter({ type, adminProducerIds }) {
	if (type === organizationTypes.PRODUCER && adminProducerIds?.length) {
		return adminProducerIds.map((producerId) => `organizationIds:${producerId}`).join(" OR ");
	}
}

export default function SuperAdminUsersView({ type }) {
	const { t } = useTranslation();
	const { adminProducerIds } = useProfile();

	const navigate = useNavigate();
	const columns = useSuperAdminUsersColumns({ type });

	const [searchInput, searchQuery] = useSearchInput();

	const filters = [type && `organizationTypes:${type}`, getAdminProducerIdsFilter({ type, adminProducerIds })]
		.filter(Boolean)
		.join(" AND ");

	const headers = {
		narrator: t("narrators", "Narrators"),
		producer: t("producers", "Producers"),
		publisher: t("publishers", "Publishers"),
		unassigned: t("unassigned", "Unassigned"),
	};

	return (
		<>
			<AdminHeader text={headers[type] || t("allUsers", "All users")}>
				{searchInput}

				<AddUserButton />
			</AdminHeader>

			<InstantSearchDataTable
				indexName="users"
				searchQuery={searchQuery}
				columns={columns}
				filters={filters}
				onClick={({ id }) => navigate(id)}
			/>
		</>
	);
}
